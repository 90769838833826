import { CssBaseline, Unstable_Grid2 as Grid2 } from '@mui/material';
import { type FC, lazy, StrictMode, Suspense } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { ApolloProvider } from 'core/Apollo';
import { Auth, AuthRoute } from 'core/Auth';
import { useUnreadCountState } from 'core/utils/intercom';
import { Notifications } from 'shared/layers/Notification';
import { ConfigProvider } from 'shared/utils/ConfigProvider';
import { ErrorBoundary } from 'shared/utils/ErrorBoundary';

import { FeatureHubFlagProvider } from 'core/flags';
import { LoadingSpinner } from 'shared/elements/LoadingSpinner/LoadingSpinner';

const App = lazy(() => import('./App'));
const AuditLogs = lazy(() => import('./App/AuditLogs'));
const Login = lazy(() => import('./App/Login'));
const Welcome = lazy(() => import('./App/Welcome'));

// If we've specified a value for VITE_SPEEDY_EMOTION as an override, use it;
// otherwise let it use its default behavior (true in production, false otherwise)
// The underlying option is defined here: https://www.npmjs.com/package/@emotion/sheet/v/1.2.2#speedy
const speedy =
  import.meta.env.VITE_SPEEDY_EMOTION === 'true'
    ? true
    : import.meta.env.VITE_SPEEDY_EMOTION === 'false'
    ? false
    : undefined;
const emotionCache = createCache({
  key: 'css',
  prepend: true,
  speedy,
});

export const Root: FC = () => {
  const [, setNotificationCount] = useUnreadCountState();

  return (
    <StrictMode>
      <CacheProvider value={emotionCache}>
        <Auth0Provider
          domain={import.meta.env.VITE_AUTH0_DOMAIN}
          clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
          authorizationParams={{
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            redirect_uri: window.location.origin,
          }}
          cacheLocation="localstorage"
          useRefreshTokens
        >
          <IntercomProvider
            appId={import.meta.env.VITE_INTERCOM_ID}
            autoBoot={false}
            autoBootProps={{
              backgroundColor: '#13c2dd',
              hideDefaultLauncher: true,
            }}
            shouldInitialize={import.meta.env.isProd}
            onUnreadCountChange={setNotificationCount}
          >
            <BrowserRouter>
              {/* missing "encode: true" in options? */}
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <ConfigProvider>
                  <CssBaseline enableColorScheme />
                  <HelmetProvider>
                    <Helmet titleTemplate="%s | Query" defaultTitle="Query" />
                    <ApolloProvider>
                      <ErrorBoundary>
                        <Notifications>
                          <Auth>
                            <FeatureHubFlagProvider>
                              <Suspense
                                fallback={
                                  <Grid2 container height={'100vh'} justifyContent={'center'} alignItems={'center'}>
                                    <LoadingSpinner size="large" />
                                  </Grid2>
                                }
                              >
                                <Routes>
                                  <Route path="login" element={<Login />} />
                                  <Route path="welcome" element={<AuthRoute element={<Welcome />} />} />
                                  <Route path="audit" element={<AuthRoute element={<AuditLogs />} />} />
                                  <Route path="/*" element={<AuthRoute element={<App />} />} />
                                </Routes>
                              </Suspense>
                            </FeatureHubFlagProvider>
                          </Auth>
                        </Notifications>
                      </ErrorBoundary>
                    </ApolloProvider>
                  </HelmetProvider>
                </ConfigProvider>
              </QueryParamProvider>
            </BrowserRouter>
          </IntercomProvider>
        </Auth0Provider>
      </CacheProvider>
    </StrictMode>
  );
};
