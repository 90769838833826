import get from 'lodash/get';
import type { PartialDeep, Get, LiteralToPrimitive, IsUnknown } from 'type-fest';

// These utility methods are lightweight wrappers around lodash's get() that provide type safety for
// the values we're retrieving. They're useful for safely accessing deeply nested values in objects
// with less risk of runtime errors due to missing properties or unexpected types.

/**
 * Given a source object and a path, returns the value at that path, but only if it exists and is of
 * a string type. Otherwise, returns undefined.
 *
 * If we're able to determine a more specific type than `string` for the value at the path (e.g. if
 * we know the string is from a union of possible strings), we'll return that specific type instead.
 *
 * @param source The source object to retrieve the value from. Can be an object, array, etc.
 * @param path The path to the value to retrieve. Supports the same path formats as lodash's get().
 */
export function getString<TSource, TPath extends string>(
  source: TSource,
  path: TPath
): IsUnknown<Get<TSource, TPath>> extends true
  ? string | undefined
  : LiteralToPrimitive<Get<TSource, TPath>> extends string
  ? Extract<Get<TSource, TPath>, string>
  : string extends LiteralToPrimitive<Get<TSource, TPath>>
  ? Extract<Get<TSource, TPath>, string> | undefined
  : string | undefined;
export function getString<TSource, TPath extends readonly string[]>(
  source: TSource,
  path: [...TPath]
): IsUnknown<Get<TSource, TPath>> extends true
  ? string | undefined
  : LiteralToPrimitive<Get<TSource, TPath>> extends string
  ? Extract<Get<TSource, TPath>, string>
  : string extends LiteralToPrimitive<Get<TSource, TPath>>
  ? Extract<Get<TSource, TPath>, string> | undefined
  : string | undefined;
export function getString(source: unknown, path: string | readonly string[]) {
  return getValueOfType(source, path, 'string');
}

/**
 * Given a source object and a path, returns the value at that path, but only if it exists and is of
 * a number type. Otherwise, returns undefined.
 *
 * If we're able to determine a more specific type than `number` for the value at the path (e.g. if
 * we know the number is from a union of possible numbers), we'll return that specific type instead.
 *
 * @param source The source object to retrieve the value from. Can be an object, array, etc.
 * @param path The path to the value to retrieve. Supports the same path formats as lodash's get().
 */
export function getNumber<TSource, TPath extends string>(
  source: TSource,
  path: TPath
): IsUnknown<Get<TSource, TPath>> extends true
  ? number | undefined
  : LiteralToPrimitive<Get<TSource, TPath>> extends number
  ? Extract<Get<TSource, TPath>, number>
  : number extends LiteralToPrimitive<Get<TSource, TPath>>
  ? Extract<Get<TSource, TPath>, number> | undefined
  : number | undefined;
export function getNumber<TSource, TPath extends readonly string[]>(
  source: TSource,
  path: [...TPath]
): IsUnknown<Get<TSource, TPath>> extends true
  ? number | undefined
  : LiteralToPrimitive<Get<TSource, TPath>> extends number
  ? Extract<Get<TSource, TPath>, number>
  : number extends LiteralToPrimitive<Get<TSource, TPath>>
  ? Extract<Get<TSource, TPath>, number> | undefined
  : number | undefined;
export function getNumber(source: unknown, path: string | readonly string[]) {
  return getValueOfType(source, path, 'number');
}

/**
 * Given a source object and a path, returns the value at that path, but only if it exists and is of
 * a boolean type. Otherwise, returns undefined.
 *
 * If we're able to determine a more specific type than `boolean` for the value at the path (e.g. if
 * we know that the value will always be `true` for the given type), we'll return that specific
 * type instead.
 *
 * @param source The source object to retrieve the value from. Can be an object, array, etc.
 * @param path The path to the value to retrieve. Supports the same path formats as lodash's get().
 */
export function getBoolean<TSource, TPath extends string>(
  source: TSource,
  path: TPath
): IsUnknown<Get<TSource, TPath>> extends true
  ? boolean | undefined
  : LiteralToPrimitive<Get<TSource, TPath>> extends boolean
  ? Extract<Get<TSource, TPath>, boolean>
  : boolean | undefined;
export function getBoolean<TSource, TPath extends readonly string[]>(
  source: TSource,
  path: [...TPath]
): IsUnknown<Get<TSource, TPath>> extends true
  ? boolean | undefined
  : LiteralToPrimitive<Get<TSource, TPath>> extends boolean
  ? Extract<Get<TSource, TPath>, boolean>
  : boolean | undefined;
export function getBoolean(source: unknown, path: string | readonly string[]) {
  return getValueOfType(source, path, 'boolean');
}

/**
 * Given a source object and a path, returns the value at that path, but only if it exists and is of
 * an object type. Otherwise, returns an empty object. Assumes that any object we return might not
 * be the full object, so we'll return it as deeply partial.
 *
 * @param source The source object to retrieve the value from. Can be an object, array, etc.
 * @param path The path to the value to retrieve. Supports the same path formats as lodash's get().
 */
export function getObject<TSource, TPath extends string>(
  source: TSource,
  path: TPath
): IsUnknown<Get<TSource, TPath>> extends true
  ? Record<string, unknown>
  : Get<TSource, TPath> extends Record<string, unknown>
  ? PartialDeep<Get<TSource, TPath>>
  : Extract<Get<TSource, TPath>, Record<string, unknown>> extends Record<string, unknown>
  ? PartialDeep<Extract<Get<TSource, TPath>, Record<string, unknown>>>
  : Record<string, unknown>;
export function getObject<TSource, TPath extends readonly string[]>(
  source: TSource,
  path: [...TPath]
): IsUnknown<Get<TSource, TPath>> extends true
  ? Record<string, unknown>
  : Get<TSource, TPath> extends Record<string, unknown>
  ? PartialDeep<Get<TSource, TPath>>
  : Extract<Get<TSource, TPath>, Record<string, unknown>> extends Record<string, unknown>
  ? PartialDeep<Extract<Get<TSource, TPath>, Record<string, unknown>>>
  : Record<string, unknown>;
export function getObject<TSource, TPath extends string | readonly string[]>(source: TSource, path: TPath) {
  const value = get(source, path);

  if (value === undefined || value === null) {
    return {};
  }

  if (typeof value !== 'object') {
    console.warn(`Expected value at path "${path}" to be an object, but got ${typeof value} instead.`, {
      source,
      path,
      value,
    });
    return {};
  }

  return value;
}

/**
 * Given a source object and a path, returns the value at that path, but only if it exists and is of
 * an array type. Otherwise, returns an empty array. Assumes that any object within the array might
 * not be the full object, so we'll return it as deeply partial.
 *
 * @param source The source object to retrieve the value from. Can be an object, array, etc.
 * @param path The path to the value to retrieve. Supports the same path formats as lodash's get().
 */
export function getArray<
  TSource,
  TPath extends string,
  T extends Get<TSource, TPath> extends (infer U)[] | undefined ? U[] : unknown[],
>(source: TSource, path: TPath): T extends (infer U)[] | undefined ? PartialDeep<U>[] : [];
export function getArray<
  TSource,
  TPath extends readonly string[],
  T extends Get<TSource, TPath> extends (infer U)[] | undefined ? U[] : unknown[],
>(source: TSource, path: [...TPath]): T extends (infer U)[] | undefined ? PartialDeep<U>[] : [];
export function getArray<TSource, TPath extends string | readonly string[]>(source: TSource, path: TPath) {
  const value = get(source, path);

  if (value === undefined || value === null) {
    return [];
  }

  if (!Array.isArray(value)) {
    console.warn(`Expected value at path "${path}" to be an array, but got ${typeof value} instead.`, {
      source,
      path,
      value,
    });
    return [];
  }

  return value;
}

function getValueOfType(
  source: unknown,
  path: string | readonly string[],
  expectedType: 'boolean'
): boolean | undefined;
function getValueOfType(source: unknown, path: string | readonly string[], expectedType: 'number'): number | undefined;
function getValueOfType(source: unknown, path: string | readonly string[], expectedType: 'string'): string | undefined;
function getValueOfType<TExpected extends 'number' | 'string' | 'boolean'>(
  source: unknown,
  path: string | readonly string[],
  expectedType?: TExpected
): boolean | number | string | undefined {
  const value = get(source, path);

  if (value === undefined || value === null) {
    return undefined;
  }

  if (expectedType !== undefined && typeof value !== expectedType) {
    console.warn(`Expected value at path "${path}" to be ${expectedType}, but got ${typeof value} instead.`, {
      source,
      path,
      expectedType,
      actualType: typeof value,
      value,
    });
    return undefined;
  }

  return value;
}
