import classNames from 'classnames';
import { motion } from 'framer-motion';
import { type FC, forwardRef, type Ref } from 'react';
import css from './Button.module.css';

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<object>, 'onAnimationStart' | 'onDragStart' | 'onDragEnd' | 'onDrag'> {
  color?: 'default' | 'primary' | 'secondary' | 'error';
  variant?: 'filled' | 'outline' | 'text';
  fullWidth?: boolean;
  disableMargins?: boolean;
  disablePadding?: boolean;
  disableAnimation?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export const Button: FC<
  ButtonProps & {
    ref?: Ref<HTMLButtonElement>;
  }
> = forwardRef(
  (
    {
      children,
      type,
      fullWidth,
      color,
      variant,
      size,
      className,
      disableMargins,
      disableAnimation,
      disablePadding,
      disabled,
      ...rest
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <motion.button
      {...rest}
      disabled={disabled}
      ref={ref}
      whileTap={{ scale: disabled || disableAnimation ? 1 : 0.9 }}
      type={type || 'button'}
      className={classNames(className, css.btn, {
        [css.default]: !color || color === 'default',
        [css.primary]: color === 'primary',
        [css.secondary]: color === 'secondary',
        [css.error]: color === 'error',
        [css.filled]: !variant || variant === 'filled',
        [css.outline]: variant === 'outline',
        [css.text]: variant === 'text',
        [css.fullWidth]: fullWidth,
        [css.small]: size === 'small',
        [css.medium]: !size || size === 'medium',
        [css.large]: size === 'large',
        [css.disableMargins]: disableMargins,
        [css.disablePadding]: disablePadding,
      })}
    >
      {children}
    </motion.button>
  )
);
