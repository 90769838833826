import { Typography } from '@mui/material';
import LogoIcon from 'assets/brand/mark.svg?react';
import type { FC } from 'react';
import { MuiButton } from 'shared/elements/Button';
import { Unstable_Grid2 as Grid2 } from '@mui/material';
import { muiTheme } from 'shared/theme';

interface LoginErrorProps {
  errorMessage: string;
  handleRedirect: () => JSX.Element;
}

export const LoginError: FC<LoginErrorProps> = ({ errorMessage, handleRedirect }) => (
  <>
    <Grid2
      container
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
      height={'100vh'}
      gap={muiTheme.spacing(2)}
      maxWidth={'50%'}
      margin={'auto'}
    >
      <LogoIcon height={52} width={52} />
      <Typography variant="h2">There was an error logging into the application.</Typography>
      <Typography>Message: {errorMessage}</Typography>
      <MuiButton variant="text" onClick={handleRedirect} size="large">
        Back to Login
      </MuiButton>
    </Grid2>
  </>
);
