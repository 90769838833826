import { createTheme, type CSSInterpolation, type ThemeOptions } from '@mui/material';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import {
  DARK_FEEDBACK_INFO,
  DARK_FEEDBACK_POSITIVE,
  DARK_MODAL_BACKGROUND,
  DARK_TEXT_PRIMARY,
  DARK_TEXT_SECONDARY,
  LIGHT_CAUTION,
  LIGHT_CAUTION_BACKGROUND,
  LIGHT_CONTAINER_BACKGROUND,
  LIGHT_INFO,
  LIGHT_INFO_BACKGROUND,
  LIGHT_INTERACTIVE_ACTIVE,
  LIGHT_INTERACTIVE_CAUTION_HOVER,
  LIGHT_INTERACTIVE_INFO_HOVER,
  LIGHT_INTERACTIVE_NEGATIVE_HOVER,
  LIGHT_INTERACTIVE_PRIMARY_HOVER,
  LIGHT_INTERACTIVE_PRIMARY_PRESSED,
  LIGHT_INTERACTIVE_SECONDARY_HOVER,
  LIGHT_INTERACTIVE_SECONDARY_PRESSED,
  LIGHT_INTERACTIVE_SELECTED,
  LIGHT_MODAL_BACKGROUND,
  LIGHT_NEGATIVE,
  LIGHT_NEGATIVE_BACKGROUND,
  LIGHT_PAGE_BACKGROUND,
  LIGHT_RULES,
  LIGHT_TEXT_BUTTON,
  LIGHT_TEXT_DISABLED,
  LIGHT_TEXT_NEGATIVE,
  LIGHT_TEXT_PRIMARY,
  LIGHT_TEXT_SECONDARY,
  LIGHT_TEXT_TERTIARY,
  LIGHT_TEXT_TOOLTIP,
  LIGHT_TOOLTIP_BACKGROUND,
} from './colors';
import { largeButtonSize, mediumButtonSize, muiTheme, smallButtonSize } from './theme';

const iconButtonLightColors: CSSInterpolation = {
  color: LIGHT_TEXT_SECONDARY,
  ':hover': {
    color: LIGHT_INTERACTIVE_ACTIVE,
    backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
  },
  ':active': {
    color: LIGHT_INTERACTIVE_ACTIVE,
    backgroundColor: LIGHT_INTERACTIVE_SECONDARY_PRESSED,
  },
  ':disabled': {
    color: LIGHT_TEXT_DISABLED,
  },
};

export const muiLightTheme = createTheme(
  muiTheme as ThemeOptions,
  {
    typography: {
      link: {
        color: LIGHT_INTERACTIVE_ACTIVE,
      },
      secondary: {
        color: LIGHT_TEXT_SECONDARY,
      },
    },
    palette: {
      mode: 'light',
      primary: {
        main: LIGHT_INTERACTIVE_ACTIVE,
        light: LIGHT_INTERACTIVE_PRIMARY_HOVER,
        dark: LIGHT_INTERACTIVE_PRIMARY_PRESSED,
      },
      secondary: {
        main: LIGHT_INTERACTIVE_ACTIVE,
        light: LIGHT_INTERACTIVE_SECONDARY_HOVER,
        dark: LIGHT_INTERACTIVE_SECONDARY_PRESSED,
      },
      text: {
        disabled: LIGHT_TEXT_DISABLED,
        primary: LIGHT_TEXT_PRIMARY,
        secondary: LIGHT_TEXT_SECONDARY,
      },
      background: {
        default: LIGHT_PAGE_BACKGROUND,
        paper: LIGHT_CONTAINER_BACKGROUND,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: LIGHT_PAGE_BACKGROUND,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.tertiary': {
              color: LIGHT_TEXT_TERTIARY,
            },
            '&.secondary': {
              color: LIGHT_TEXT_SECONDARY,
            },
            '&.disabled': {
              color: LIGHT_TEXT_DISABLED,
            },
          },
          link: {
            ':hover': {
              color: LIGHT_INTERACTIVE_PRIMARY_HOVER,
            },
            '&.current': {
              color: LIGHT_INTERACTIVE_PRIMARY_PRESSED,
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            ':hover': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
            },
            '&.Mui-disabled > .MuiAccordionSummary-content': {
              color: LIGHT_TEXT_DISABLED,
            },
            '&.Mui-disabled > .MuiAccordionSummary-expandIconWrapper': {
              color: LIGHT_TEXT_DISABLED,
            },
            '&.Mui-disabled > .MuiAccordionSummary-content > p': {
              color: LIGHT_TEXT_DISABLED,
            },
          },
          expandIconWrapper: {
            color: LIGHT_TEXT_PRIMARY,
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'menu' },
            style: {
              padding: '0.625rem 1rem',
            },
          },
          {
            props: { variant: 'text', color: 'primary' },
            style: {
              padding: 0,
              ':hover': {
                backgroundColor: 'transparent',
                color: LIGHT_INTERACTIVE_PRIMARY_HOVER,
                ' svg': {
                  fill: LIGHT_INTERACTIVE_PRIMARY_HOVER,
                },
              },
              ':disabled': {
                color: LIGHT_TEXT_DISABLED,
              },
            },
          },
        ],
        styleOverrides: {
          menu: {
            backgroundColor: LIGHT_TEXT_BUTTON,
            color: LIGHT_TEXT_SECONDARY,
            ':hover': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
              color: LIGHT_TEXT_SECONDARY,
            },
            ':active': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_PRESSED,
              color: LIGHT_TEXT_SECONDARY,
            },
            ':disabled': {
              color: LIGHT_TEXT_DISABLED,
            },
          },
          contained: {
            color: '#FAFAFA',
            ':hover': { backgroundColor: LIGHT_INTERACTIVE_PRIMARY_HOVER },
            ':active': { backgroundColor: LIGHT_INTERACTIVE_PRIMARY_PRESSED },
            ':disabled': { backgroundColor: LIGHT_TEXT_DISABLED, color: LIGHT_TEXT_TERTIARY },
          },
          outlined: {
            color: LIGHT_INTERACTIVE_ACTIVE,
            borderColor: LIGHT_INTERACTIVE_SECONDARY_PRESSED,
            ':hover': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
              color: LIGHT_INTERACTIVE_ACTIVE,
              borderColor: LIGHT_INTERACTIVE_ACTIVE,
            },
            ':active': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_PRESSED,
              color: LIGHT_INTERACTIVE_ACTIVE,
              borderColor: LIGHT_INTERACTIVE_ACTIVE,
            },
            ':disabled': {
              color: LIGHT_TEXT_DISABLED,
              borderColor: LIGHT_TEXT_DISABLED,
            },
          },
          sizeLarge: largeButtonSize,
          sizeMedium: mediumButtonSize,
          sizeSmall: smallButtonSize,
        },
      },
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            ...iconButtonLightColors,
            padding: '0.25rem',
            borderRadius: '1rem',
          },
          sizeSmall: {
            ...iconButtonLightColors,
            padding: '0.1875rem',
            borderRadius: '0.75rem',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: LIGHT_TEXT_DISABLED,
            '&.Mui-checked': {
              color: LIGHT_INTERACTIVE_ACTIVE,
              ':hover': {
                backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
                color: LIGHT_INTERACTIVE_PRIMARY_HOVER,
              },
              ':active': {
                backgroundColor: LIGHT_INTERACTIVE_SECONDARY_PRESSED,
                color: LIGHT_INTERACTIVE_PRIMARY_PRESSED,
              },
            },
            ':hover': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
              color: LIGHT_INTERACTIVE_PRIMARY_HOVER,
            },
            ':active': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_PRESSED,
              color: LIGHT_INTERACTIVE_PRIMARY_PRESSED,
            },
            '&.Mui-disabled': {
              color: LIGHT_TEXT_DISABLED,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            ':hover': {
              color: LIGHT_INTERACTIVE_PRIMARY_HOVER,
            },
            ':active': {
              color: LIGHT_INTERACTIVE_PRIMARY_PRESSED,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: LIGHT_TEXT_DISABLED,
            ':hover': {
              color: LIGHT_INTERACTIVE_PRIMARY_HOVER,
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
            },
            ':active': {
              color: LIGHT_INTERACTIVE_PRIMARY_PRESSED,
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_PRESSED,
            },
            '&.Mui-checked': {
              color: LIGHT_INTERACTIVE_ACTIVE,
              ':hover': {
                color: LIGHT_INTERACTIVE_PRIMARY_HOVER,
              },
              ':active': {
                color: LIGHT_INTERACTIVE_PRIMARY_PRESSED,
              },
            },
            '&.Mui-disabled': {
              color: LIGHT_TEXT_DISABLED,
              '&.Mui-checked': {
                /* pseudo-element necessary here because setting the background-color
              directly on the SVG takes more screen space than the checkbox itself */
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  height: '37%',
                  width: '37%',
                  top: '32%',
                  left: '32%',
                  zIndex: -1,
                  backgroundColor: LIGHT_CONTAINER_BACKGROUND,
                },
              },
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            border: `1px solid ${LIGHT_TEXT_DISABLED}`,
            '&.Mui-selected': {
              color: LIGHT_INTERACTIVE_ACTIVE,
              backgroundColor: LIGHT_INTERACTIVE_SELECTED,
              backgroundClip: 'padding-box',
              ':hover': {
                backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
                backgroundClip: 'padding-box',
              },
              border: `1px solid ${LIGHT_TEXT_DISABLED}`,
            },
            ':hover': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
              backgroundClip: 'padding-box',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            color: LIGHT_TEXT_SECONDARY,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          outlined: {
            border: `1px solid ${LIGHT_TEXT_DISABLED}`,
            backgroundColor: LIGHT_CONTAINER_BACKGROUND,
            color: LIGHT_TEXT_SECONDARY,
          },
          deletable: {
            ':hover': {
              color: LIGHT_INTERACTIVE_ACTIVE,
              border: `1px solid ${LIGHT_INTERACTIVE_ACTIVE}`,
              '> .MuiSvgIcon-root': {
                color: LIGHT_INTERACTIVE_ACTIVE,
                ':hover': {
                  color: LIGHT_INTERACTIVE_PRIMARY_HOVER,
                },
              },
            },
            ':active': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_PRESSED,
              '> .MuiSvgIcon-root': {
                ':hover': {
                  color: LIGHT_INTERACTIVE_PRIMARY_PRESSED,
                },
              },
            },
            deleteIcon: {
              color: LIGHT_TEXT_DISABLED,
            },
          },
          clickable: {
            ':hover': {
              color: LIGHT_INTERACTIVE_ACTIVE,
              border: `1px solid ${LIGHT_INTERACTIVE_ACTIVE}`,
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
              '> .MuiSvgIcon-root': {
                color: LIGHT_INTERACTIVE_ACTIVE,
                ':hover': {
                  color: LIGHT_INTERACTIVE_PRIMARY_HOVER,
                },
              },
            },
            ':active': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_PRESSED,
              '> .MuiSvgIcon-root': {
                ':hover': {
                  color: LIGHT_INTERACTIVE_PRIMARY_PRESSED,
                },
              },
            },
            deleteIcon: {
              color: LIGHT_TEXT_DISABLED,
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: LIGHT_RULES,
          },
          withChildren: {
            '&::before': {
              borderColor: LIGHT_RULES,
            },
            '&::after': {
              borderColor: LIGHT_RULES,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            ':hover': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
            },
            '& .MuiTouchRipple-child': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_PRESSED,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: LIGHT_CONTAINER_BACKGROUND,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '> .MuiSvgIcon-root:not(.Mui-disabled)': {
              color: LIGHT_TEXT_SECONDARY,
            },
            '> .MuiSvgIcon-root.Mui-disabled:not(.Mui-error)': {
              fill: LIGHT_TEXT_DISABLED,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            // Font style for placeholder text when input is in focus but no value has been added
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.125rem',
            '&.MuiOutlinedInput-root:not(.Mui-error):not(.Mui-disabled)': {
              ':hover:not(.Mui-focused) > fieldset': {
                // Set border color on hover, but not when focused
                borderColor: LIGHT_TEXT_SECONDARY,
              },
              ':active > fieldset': {
                // Keep border color from changing when holding down mouse click on field
                borderColor: LIGHT_INTERACTIVE_ACTIVE,
              },
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              // Set border color on error
              borderColor: LIGHT_TEXT_NEGATIVE,
            },
            '&.MuiOutlinedInput-root.Mui-disabled > fieldset': {
              borderColor: LIGHT_TEXT_DISABLED,
            },
            '&.MuiOutlinedInput-root.Mui-disabled:not(.Mui-error)': {
              '& > input': {
                color: LIGHT_TEXT_DISABLED,
                WebkitTextFillColor: LIGHT_TEXT_DISABLED,
              },
            },
          },
          notchedOutline: {
            borderColor: LIGHT_RULES,
          },
          input: {
            '::placeholder': {
              color: LIGHT_TEXT_TERTIARY,
            },
            color: LIGHT_TEXT_PRIMARY,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: LIGHT_TEXT_SECONDARY,
            '&.Mui-error': {
              color: LIGHT_TEXT_NEGATIVE,
            },
            '&.Mui-disabled': {
              color: LIGHT_TEXT_DISABLED,
            },
          },
          filled: {
            // Text color of value in input
            '& + div.MuiOutlinedInput-root': {
              color: LIGHT_TEXT_PRIMARY,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: LIGHT_TEXT_TERTIARY,
            '&.Mui-error': {
              color: LIGHT_TEXT_NEGATIVE,
            },
            '&.Mui-disabled': {
              color: LIGHT_TEXT_DISABLED,
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            ':hover': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
            },
            '&.Mui-selected': {
              backgroundColor: LIGHT_INTERACTIVE_SELECTED,
            },
          },
        },
      },
      MuiDateRangeCalendar: {
        styleOverrides: {
          root: {
            border: `1px solid ${LIGHT_TEXT_DISABLED}`,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            ':hover': {
              backgroundColor: LIGHT_INTERACTIVE_SECONDARY_HOVER,
            },
            '&.Mui-disabled:not(.Mui-selected)': {
              color: LIGHT_TEXT_DISABLED,
            },
            '&.Mui-selected': {
              color: LIGHT_TEXT_BUTTON,
              ':hover': {
                backgroundColor: LIGHT_INTERACTIVE_PRIMARY_HOVER,
              },
              ':focus': {
                backgroundColor: LIGHT_INTERACTIVE_PRIMARY_PRESSED,
              },
            },
          },
        },
      },
      MuiMultiSectionDigitalClockSection: {
        styleOverrides: {
          item: {
            '&.Mui-selected': {
              backgroundColor: LIGHT_INTERACTIVE_ACTIVE,
              color: LIGHT_TEXT_BUTTON,
              ':hover': {
                backgroundColor: LIGHT_INTERACTIVE_PRIMARY_HOVER,
              },
            },
            ':hover': {
              backgroundColor: LIGHT_INTERACTIVE_PRIMARY_HOVER,
              color: LIGHT_TEXT_BUTTON,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            '&.Mui-disabled': {
              WebkitTextFillColor: LIGHT_TEXT_DISABLED,
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            '&.driver-card:hover': {
              borderColor: LIGHT_TEXT_SECONDARY,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: LIGHT_TOOLTIP_BACKGROUND,
            color: LIGHT_TEXT_TOOLTIP,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: LIGHT_TEXT_SECONDARY,
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            '.MuiAlert-root': {
              color: DARK_TEXT_PRIMARY,
              backgroundColor: DARK_MODAL_BACKGROUND,
            },
            '.MuiAlert-action': {
              '.MuiIconButton-root': {
                color: DARK_TEXT_PRIMARY,
                ':hover': {
                  backgroundColor: 'transparent',
                },
              },
            },
            '.MuiAlert-standardInfo': {
              '.MuiAlert-icon': {
                color: DARK_FEEDBACK_INFO,
              },
            },
            '.MuiAlert-standardSuccess': {
              '.MuiAlert-icon': {
                color: DARK_FEEDBACK_POSITIVE,
              },
            },
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            backgroundColor: DARK_MODAL_BACKGROUND,
          },
          action: {
            '& > button > svg': { fill: DARK_TEXT_SECONDARY },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standard: {
            color: LIGHT_TEXT_PRIMARY,
          },
          action: {
            ' button:hover': {
              color: LIGHT_TEXT_PRIMARY,
            },
          },
          standardError: {
            backgroundColor: LIGHT_NEGATIVE_BACKGROUND,
            '.MuiAlert-action': {
              ' button:hover': {
                backgroundColor: LIGHT_INTERACTIVE_NEGATIVE_HOVER,
              },
            },
            '.MuiAlert-icon': {
              color: LIGHT_NEGATIVE,
            },
          },
          standardWarning: {
            backgroundColor: LIGHT_CAUTION_BACKGROUND,
            '.MuiAlert-action': {
              ' button:hover': {
                backgroundColor: LIGHT_INTERACTIVE_CAUTION_HOVER,
              },
            },
            '.MuiAlert-icon': {
              color: LIGHT_CAUTION,
            },
          },
          standardInfo: {
            backgroundColor: LIGHT_INFO_BACKGROUND,
            '.MuiAlert-action': {
              ' button:hover': {
                backgroundColor: LIGHT_INTERACTIVE_INFO_HOVER,
              },
            },
            '.MuiAlert-icon': {
              color: LIGHT_INFO,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: LIGHT_MODAL_BACKGROUND,
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            backgroundColor: LIGHT_MODAL_BACKGROUND,
            backgroundImage: 'none',
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: LIGHT_TEXT_PRIMARY,
          },
        },
      },
    },
  } as ThemeOptions
);
